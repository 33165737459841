import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Process1 from '../../../images/work/23_THE_WAVE/PROCESS/WAVE_01.jpg';
import Process2 from '../../../images/work/23_THE_WAVE/PROCESS/WAVE_02.jpg';
import Process3 from '../../../images/work/23_THE_WAVE/PROCESS/WAVE_03.jpg';
import Process4 from '../../../images/work/23_THE_WAVE/PROCESS/WAVE_04.jpg';
import Process5 from '../../../images/work/23_THE_WAVE/PROCESS/WAVE_05.jpg';
// import Process6 from '../../../images/work/23_THE_WAVE/PROCESS/WAVE_06.jpg';
import Process1s from '../../../images/work/23_THE_WAVE/PROCESS/450X280/WAVE_SM_01.jpg';
import Process2s from '../../../images/work/23_THE_WAVE/PROCESS/450X280/WAVE_SM_02.jpg';
import Process3s from '../../../images/work/23_THE_WAVE/PROCESS/450X280/WAVE_SM_03.jpg';
import Process4s from '../../../images/work/23_THE_WAVE/PROCESS/450X280/WAVE_SM_04.jpg';
import Process5s from '../../../images/work/23_THE_WAVE/PROCESS/450X280/WAVE_SM_05.jpg';
// import Process6s from '../../../images/work/23_THE_WAVE/PROCESS/450X280/WAVE_SM_06.jpg';

const Residence23 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="THE WAVE"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={[
          'Demolition of existing single family residence and construction of a new 7,500 SF residence.',
          'Design - 2020-2021 (in progress)',
        ]}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[
          Process1,
          Process2,
          Process3,
          Process4,
          Process5,
          // Process6,
        ]}
        processPhotosSmall={[
          Process1s,
          Process2s,
          Process3s,
          Process4s,
          Process5s,
          // Process6s,
        ]}
      />
    </Container>
  </Layout>
);

export default Residence23;
